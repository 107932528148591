const hideFormFields = () => {
    const formSuccessMessage = document.querySelector('form .Form__Status .Form__Success__Message');
    const formElements = document.querySelectorAll('.Form__Title, .Form__Description, .Form__MainBody') as NodeList;

    if (!formSuccessMessage && !formElements) return;  

    if (formSuccessMessage && formElements) {
        formElements.forEach((formElement: any) => {
            formElement.style.display = 'none';
        })
    }
}

document.addEventListener('DOMContentLoaded', hideFormFields);

export default hideFormFields;
