import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useInView } from 'react-intersection-observer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import Bubbles from '4-sections/Bubbles/Bubbles';
import Menu from '3-components/Menu/Menu';
import EraControls from '3-components/EraControls/EraControls';
import AddThisAddScripts from '3-components/AddThis/AddThisAddScript';
import CONSTANTS from '../../js/constants';

const el = document.getElementById('app-experience-space');

function ExperienceSpace() {
    const container = useRef<HTMLElement>(null);
    const [eraDataLoaded, setEraDataLoaded] = useState(false);
    const [leftScroll, setLeftScroll] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState('');
    const marginRef = useRef([0, 0]);
    const { ANIMATION_TIME } = CONSTANTS;
    const { ref, inView } = useInView({ threshold: 0.8 });

    const setScrollTo =
    (x:number | null, y:number | null, absolute?:boolean, bubSize?:number, maxScroll?:number[], eraControl?:boolean) => {
        if (container && container.current) {
            if (absolute) {
                if (x !== null && y !== null) {
                    container.current.scrollTo(x, y);
                    setLeftScroll(x);
                } else {
                    // closing modal behaviour
                    setModalIsOpen('transition');
                    const currentTransform = container.current.style.transform;
                    const regExpLeft = /calc\(([^)]+)px\)/;
                    const matchesLeft = regExpLeft.exec(currentTransform);
                    const regExpTop = /, calc\(([^)]+)px\)/;
                    const matchesTop = regExpTop.exec(currentTransform);
                    const scrollTop = matchesTop ? parseInt(matchesTop[1], 10) * -1 : 0;
                    const scrollLeft = matchesLeft ? parseInt(matchesLeft[1], 10) * -1 : 0;
                    const scrollCalc = scrollLeft ? scrollLeft : 1;
                    const leftMargin = maxScroll && maxScroll.length > 1 && scrollCalc > maxScroll[0] ? maxScroll[0] : scrollCalc;
                    const topMargin = scrollTop ? scrollTop : 1;
                    if (!leftMargin && !topMargin) {
                        return;
                    }
                    container.current.style.transform = `translate(${leftMargin < 0 ? leftMargin : -leftMargin}px, -${topMargin}px)`;
                    setTimeout(() => {
                        // set time out for length of the animation before switching out the scroll offset and margin
                        // and setting style values back to interactive
                        if (container && container.current) {
                            container.current.style.overflow = 'auto';
                            container.current.scrollTo(leftMargin, topMargin);
                            container.current.style.transform = '';
                            container.current.style.transition = '';
                            container.current.style.pointerEvents = '';
                            marginRef.current = [];
                            setModalIsOpen('');
                        }
                    }, ANIMATION_TIME);
                }
            } else {
                // opening modal behaviour
                setModalIsOpen('transition');
                const scrollX = x ? x : 0;
                const topMargin = container.current.scrollTop;
                const leftMargin = container.current.scrollLeft;
                const currentTransform = `translate(-${leftMargin}px, -${topMargin}px)`;
                container.current.style.transform = currentTransform;
                container.current.style.pointerEvents = 'none';
                container.current.scrollTo((0), (0));
                container.current.style.overflow = 'visible';
                marginRef.current[0] = y && bubSize ? y - bubSize : 0;
                marginRef.current[1] = leftMargin;
                setLeftScroll((scrollX - (0 * 0.80) + 1) * -1);

                setTimeout(() => {
                    // set time out for a very short time to apply the correct class to smoothly scroll
                    if (container && container.current) {
                        container.current.style.transform =
                        `translate(calc(${scrollX}px), calc(${y}px))`;
                        container.current.style.transition = 'transform 1s ease-in-out';
                    }
                }, 10);
                setTimeout(() => {
                    setModalIsOpen('open');
                }, ANIMATION_TIME);
                if (eraControl) {
                    setTimeout(() => {
                        // set time out to allow for smooth scoll but then set the values to closed modal
                        setScrollTo(null, null, true, undefined, maxScroll);
                    }, 1000);
                }
            }
        }
    };

    const onEndScroll = () => {
        // TODO move array of eras to this level and use left scroll ref so we don't trigger rerender of exspace on every scroll
        if (container.current?.scrollLeft) {
            setLeftScroll(container.current.scrollLeft);
        }
    };

    const memoizedSetScrollTo = useCallback(setScrollTo, []);
    const memoizedOnEndScroll = useCallback(onEndScroll, []);

    useEffect(() => {
        AddThisAddScripts();
    }, []);

    return (
        <Router>
            <div ref={ref} className="observer">
                <img src="/Static/twoHundredYears/assets/illustrations/logo-horizontal-stacked.svg" alt="Allens linklaters, 200 years" className="logo" />

                <Menu inView={inView} />
                <ScrollContainer
                    className={`experience-space${modalIsOpen ? ` ${modalIsOpen}` : ''}`}
                    innerRef={container}
                    onEndScroll={eraDataLoaded ? memoizedOnEndScroll : undefined}
                    ignoreElements=".no-scroll"
                >

                    <Routes>
                        <Route
                            path="*"
                            element={(
                                <Bubbles
                                    setEraDataLoaded={setEraDataLoaded}
                                    setScrollTo={memoizedSetScrollTo}
                                    scrollContainer={container}
                                    inView={inView}
                                />
                            )}
                        />
                    </Routes>

                </ScrollContainer>

                {eraDataLoaded && (
                    <EraControls
                        scrollLeft={leftScroll}
                        dataLoaded={eraDataLoaded}
                        setScrollTo={memoizedSetScrollTo}
                        modalIsOpen={!!modalIsOpen}
                    />
                ) }

            </div>
        </Router>
    );
}

if (el) {
    ReactDOM.render(
        <ExperienceSpace />,
        el
    );
}
