import attachEnterAsClick from 'utils/attachEnterAsClick';

const accordionItemHeadingClick = function (this: HTMLElement) {
    // Slide open the accordion content
    const contentItem = this.closest<HTMLElement>('.accordion-item');
    const contentInner = contentItem?.querySelector<HTMLElement>(
        '.accordion-content-display'
    );
    const content =
        contentItem?.querySelector<HTMLElement>('.accordion-content');
    if (content && contentInner) {
        if (contentItem?.classList.contains('is-open')) {
            this.setAttribute('aria-expanded', 'false');
            contentItem.classList.remove('is-open');
            content.style.height = `${contentInner.offsetHeight}px`;
            setTimeout(() => {
                content.style.height = '0px';
            }, 10);
        } else {
            this.setAttribute('aria-expanded', 'true');
            contentItem?.classList.add('is-open');
            content.style.display = 'block';
            setTimeout(() => {
                content.style.height = `${contentInner.offsetHeight}px`;
            }, 0);
        }
    }
};

const accordionItemContentTransitionEnd = function (this: HTMLElement) {
    if (this.offsetHeight > 0) {
        this.style.height = 'auto';
    }
    if (this.offsetHeight === 0 && !this.closest('.accordion-item.is-open')) {
        this.style.display = 'none';
    }
};

const accordionItemInit = (el: HTMLElement) => {
    const heading = el.querySelector<HTMLButtonElement>(
        '.accordion-heading > button'
    );
    const content = el.querySelector<HTMLElement>('.accordion-content');

    heading?.setAttribute('aria-expanded', 'false');
    heading?.addEventListener('click', accordionItemHeadingClick);
    attachEnterAsClick(heading, true);

    content?.addEventListener(
        'transitionend',
        accordionItemContentTransitionEnd
    );

    // // pre set height of open items to enable animation
    if (content && el.classList.contains('is-open')) {
        heading?.setAttribute('aria-expanded', 'true');
        const contentHeight = content?.querySelector<HTMLElement>(
            '.accordion-content-display'
        )?.offsetHeight;
        content.style.height = `${contentHeight}px`;
    }
};

const accordionInit = () => {
    const accordionItems =
        document.querySelectorAll<HTMLElement>('.accordion-item');

    if (accordionItems.length > 0) {
        accordionItems.forEach(accordionItemInit);
    }
};

document.addEventListener('DOMContentLoaded', accordionInit);

// eslint-disable-next-line import/prefer-default-export
export { accordionItemInit };
