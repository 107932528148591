import React from 'react';
import Icon from '3-components/Icon/Icon';
import ReactModal from 'react-modal';

if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body');
}

export interface ModalBasicProps {
    onCloseClick?: () => void;
    isOpen: boolean;
    ariaLabel: string;
    modalTitle?: string;
    children?: React.ReactNode;
    parentSelector?: (() => HTMLElement) | undefined;
    content: string;
    modalParentSelector: HTMLElement
    hasSlides?: boolean
}

function ModalBasic({ isOpen, onCloseClick, ariaLabel, modalTitle, content, modalParentSelector, children, hasSlides }: ModalBasicProps) {
    if (!modalParentSelector) { return null; }
    return (
        <ReactModal
            closeTimeoutMS={2000}
            isOpen={isOpen}
            onRequestClose={onCloseClick}
            contentLabel={ariaLabel}
            shouldCloseOnOverlayClick={true}
            overlayClassName="modal-overlay basic"
            className={`modal-container basic${hasSlides ? ' slides' : ''}`}
            parentSelector={() => modalParentSelector}
        >
            <button className="modal-close basic" aria-label="Close modal" type="button" onClick={onCloseClick}><Icon icon="x-large" /></button>

            <div className="modal-header basic">
                <h2>{modalTitle}</h2>
            </div>
            {content && (
                <div className="modal-content">
                    {content}
                </div>
            )}
            {children}
        </ReactModal>
    );
}
export default React.memo(ModalBasic);
