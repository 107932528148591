import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs } from 'swiper';
import Image from '3-components/Image/Image';
import RichText from '3-components/RichText/RichText';

export interface Slide {
        id?: number | null;
        videoSrc?: string;
        imgSrc?: string;
        caption?: string;
        videoThumb?: string;
}

interface SliderProps {
    slides: [Slide?];
    thumbs: boolean;
}

function Slider(props: SliderProps) {
    const { slides, thumbs } = props;
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
    if (!slides) { return null; }
    return (
        <div className={`swiper-component ${thumbs ? 'swiper-with-thumbs' : ''}`}>
            <Swiper
                modules={thumbs ? [Thumbs] : undefined}
                thumbs={thumbs ? { swiper: thumbsSwiper } : undefined}
                spaceBetween={50}
                slidesPerView={1}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }}
                pagination={{ clickable: true }}
                key="1"
            >
                {slides.length > 0 && slides.map(slide => (
                    slide && (
                        <SwiperSlide
                            key={`slide-${slide.id}`}
                        >
                            { slide.videoSrc ? (
                                <div className={`video-wrapper${slide.caption ? ' caption' : ''}`}>
                                    <iframe
                                        className={slide.videoSrc.includes('viostream.com') ? 'viocom' : ''}
                                        src={slide.videoSrc}
                                        frameBorder="0"
                                        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        allowFullScreen={true}
                                        title="video"
                                    />
                                    {slide.caption && (
                                        <div className="video-caption">
                                            <figcaption>
                                                <RichText
                                                    notRich={true}
                                                    markup={slide.caption}
                                                />
                                            </figcaption>
                                        </div>
                                    ) }
                                </div>
                            ) : (
                                <Image
                                    imgSrc={slide.imgSrc}
                                    imgAlt="test"
                                    caption={slide.caption}
                                    imgCustomClass="flexbox"
                                    objectFit="contain"
                                />
                            )}
                        </SwiperSlide>
                    )
                ))}
                <div className="swiper-button-prev " />
                <div className="swiper-button-next " />
            </Swiper>
            {(thumbs && slides.length > 1) && (
                <Swiper
                    modules={[Thumbs]}
                    watchSlidesProgress={true}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={24}
                    slidesPerView={6}
                    key="2"
                >
                    {slides.map(slide => (
                        slide && (
                            <SwiperSlide
                                key={`slide-${slide.id}`}
                            >
                                <Image
                                    imgSrc={slide.videoThumb ? slide.videoThumb : slide.imgSrc}
                                    imgWidth="160"
                                    imgHeight="160"
                                    aspectRatio="1/1"
                                    imgAlt={slide.caption ? slide.caption : `img-${slide.id}`}
                                    imgCustomClass="thumbnails"
                                />
                            </SwiperSlide>
                        )
                    ))}
                </Swiper>
            )}
        </div>
    );
}

export default Slider;
