const animateSectionThree = () => {
    const innerBlocks = document.querySelectorAll('.section-three .inner-block');
    const firstImgs = document.querySelector('.section-three .first-images');
    const secondImgs = document.querySelector('.section-three .second-images');
    const thirdImgs = document.querySelector('.section-three .third-images');
    const firstHeading = document.querySelector('.section-three h2.first-heading');
    const mainHeadings = document.querySelectorAll('.section-three h2.heading-xl');
    let scrolledPastFirstInnerBlock = false;

    const innerBlocksOptions = { threshold: 0.5 };

    const showHeading = (index: number) => {
        mainHeadings[index].classList.add('show');
    };

    const hideHeading = (index: number) => {
        mainHeadings[index].classList.remove('show');
    };

    const showImages = (images: Element | null) => {
        images?.classList.add('visible');
        images?.classList.remove('hidden');
    };

    const hideImages = (images: Element | null) => {
        images?.classList.remove('visible');
        images?.classList.add('hidden');
    };

    const innerBlocksObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const isFirstInnerBlock = entry.target.classList.contains('first-inner-block');
            const isSecondInnerBlock = entry.target.classList.contains('second-inner-block');
            const isThirdInnerBlock = entry.target.classList.contains('third-inner-block');

            if (!entry.isIntersecting) return;

            if (isFirstInnerBlock) {
                showHeading(0);
                firstHeading?.classList.add('show');

                if (scrolledPastFirstInnerBlock) {
                    showImages(firstImgs);
                }
            } else {
                hideImages(firstImgs);
                hideHeading(0);
            }

            if (isSecondInnerBlock) {
                showImages(secondImgs);
                showHeading(1);
                firstHeading?.classList.remove('show');
            } else {
                hideImages(secondImgs);
                hideHeading(1);
            }

            if (isThirdInnerBlock) {
                showImages(thirdImgs);
                showHeading(2);
            } else {
                hideImages(thirdImgs);
                hideHeading(2);
            }

            scrolledPastFirstInnerBlock = true;
        });
    }, innerBlocksOptions);

    if (innerBlocks.length > 0) {
        innerBlocks.forEach(innerBlock => {
            innerBlocksObserver.observe(innerBlock);
        });
    }
};

export default animateSectionThree;
