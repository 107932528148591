export const getYoutubeId = (url: string) => {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];

    // Tested URLs:
    // url = 'http://youtube.googleapis.com/v/4e_kz79tjb8?version=3';
    // url = 'https://www.youtube.com/watch?feature=g-vrec&v=Y1xs_xPb46M';
    // url = 'http://www.youtube.com/watch?feature=player_embedded&v=Ab25nviakcw#';
    // url = 'http://youtu.be/Ab25nviakcw';
    // url = 'http://www.youtube.com/watch?v=Ab25nviakcw';
    // url = '<iframe width="420" height="315" src="http://www.youtube.com/embed/Ab25nviakcw" frameborder="0" allowfullscreen></iframe>';
    // url = '<object width="420" height="315"><param name="movie" value="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US" type="application/x-shockwave-flash" width="420" height="315" allowscriptaccess="always" allowfullscreen="true"></embed></object>';
    // url = 'http://i1.ytimg.com/vi/Ab25nviakcw/default.jpg';
    // url = 'https://www.youtube.com/watch?v=BGL22PTIOAM&feature=g-all-xit';
    // url = 'BGL22PTIOAM';
};

export const getVimeoId = (url: string) => {
    const arr = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url);
    return arr ? arr[3] : '';
    // Tested URLs:
    // url = 'https://vimeo.com/17401578'
    // url = '<iframe src="https://player.vimeo.com/video/17401578" width="640" height="364" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe><p><a href="https://vimeo.com/17401578">Wedding video, Eskuvoi video, Svadobne video</a> from <a href="https://vimeo.com/user5118599">Tomas Fekete</a> on <a href="https://vimeo.com">Vimeo</a>.</p>'
    // url = 'https://player.vimeo.com/video/17401578'
};

export const getFileExtension = (url: string) => {
    const cleanUrl = url.split(/[#?]/)[0];
    const ext = cleanUrl.split('.').pop()?.trim();
    return ext;

    // Tested URLs:
    // url = 'http://localhost/assets/images/main.jpg?w=100&h=200#123'
    // url = 'http://localhost/assets/images/main.jpg#123'
    // url = 'http://localhost/assets/images/main.jpg'
};
