function AddThisAddScripts() {
    const scriptTag = document.createElement('script');
    scriptTag.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5bea035b29a5d9eb';
    // @ts-ignore addthis TS definition not availble
    scriptTag.addEventListener('load', () => addthis.init());
    scriptTag.id = 'script-tag';
    document.body.appendChild(scriptTag);
}

export default AddThisAddScripts;
