import React from 'react';
import RichText from '3-components/RichText/RichText';

export interface ImageProps {
    imgSrc?: string,
    imgAlt?: string,
    imgWidth?: string,
    imgHeight?: string,
    aspectRatio?: string,
    alignment?: string,
    caption?: string,
    objectFit?: string,
    disableLazyLoading?: boolean,
    imgCustomClass?: string,
    id?: number
}

function Image({
    imgSrc,
    imgAlt,
    caption,
    aspectRatio,
    alignment,
    imgWidth,
    imgHeight,
    objectFit,
    disableLazyLoading,
    imgCustomClass,
    id
}: ImageProps) {
    return (
        <div
            key={id}
            className={`image-wrapper ${imgCustomClass}`}
            style={{
                '--image-width': imgWidth,
                '--image-height': 'auto',
                '--image-alignment': alignment ? alignment : 'center center',
                '--image-object-fit': objectFit ? objectFit : 'cover',
                '--aspect-ratio': aspectRatio !== undefined ? aspectRatio : ''
            } as React.CSSProperties}
        >
            <figure>
                <picture className={aspectRatio !== undefined ? 'has-ratio' : ''}>
                    {imgSrc && (
                        <img
                            src={imgSrc}
                            // srcSet={`${src}.webp`}
                            alt={imgAlt}
                            width={imgWidth}
                            height={imgHeight}
                            // class="{{#if imgCustomClass}} {{imgCustomClass}}{{/if}}"
                            // @ts-ignore
                            loading={disableLazyLoading ? '' : 'lazy'}
                            decoding="async"
                        />
                    )}
                </picture>
                {caption && (
                    <figcaption><RichText
                        notRich={true}
                        markup={caption}
                    />
                    </figcaption>
                )}
            </figure>
        </div>
    );
}

export default Image;
