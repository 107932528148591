import kebabCase from 'lodash.kebabcase';
import camelCase from 'lodash.camelcase';

const ComponentInit = componentList => {
    Object.keys(componentList).forEach(key => {
        // get elements
        const elements = document.querySelectorAll(`[data-${kebabCase(key)}]`);

        // if app/component does not exist on the page abort
        if (!elements) return;

        // if app/component exists on page proceed
        const component = componentList[key];

        elements.forEach((el, index) => {
            // get data as string
            const data = el.dataset[camelCase(key)];

            // if the data is not null, parse the data as a string
            const props = data ? JSON.parse(data) : '';

            // if data is incorrectly get
            // props !== '' to accomodate just using data without props.
            if (typeof props === 'string' && props !== '') return;

            if (!el.dataset.init) {
                el.dataset.init = true;
                component({ el, props, index });
            }
        });
    });
};

export default ComponentInit;
