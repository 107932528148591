const animateSectionFour = () => {
    const mostOfAllHeadings = document.querySelector('.section-four .headings');
    const mostOfAllFirstHeading = mostOfAllHeadings?.querySelector('.first-heading');
    const mostOfAllMainHeadings = document.querySelectorAll('.section-four h2.heading-xl');
    const bubbleGroups = document.querySelectorAll('.bubbles-group');

    const headingsAnimation = (heading: number) => {
        mostOfAllFirstHeading?.classList.add('hide');
        mostOfAllMainHeadings[heading].previousElementSibling?.classList.remove('visible');
        mostOfAllMainHeadings[heading].previousElementSibling?.classList.add('hide');
        mostOfAllMainHeadings[heading]?.classList.add('visible');
        mostOfAllMainHeadings[heading]?.classList.remove('hide');
    };

    const hideHeading = (heading: number) => {
        mostOfAllMainHeadings[heading]?.classList.remove('visible');
    };

    const mostOfAllHeadingsOptions = { threshold: 0.95 };

    const bubbleGroupOptions = { threshold: 0.2 };

    const mostOfAllHeadingsObserver = new IntersectionObserver(entries => {
        const mainSection = entries[0];
        if (!mainSection.isIntersecting) return;
        mostOfAllFirstHeading?.classList.add('move-up');
    }, mostOfAllHeadingsOptions);

    if (mostOfAllHeadings) {
        mostOfAllHeadingsObserver.observe(mostOfAllHeadings);
    }

    const bubbleGroupObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const isFirstBubbleGroup = entry.target.classList.contains('first-bubbles-group');
            const isSecondBubbleGroup = entry.target.classList.contains('second-bubbles-group');
            const isForthBubbleGroup = entry.target.classList.contains('forth-bubbles-group');
            const isFifthBubbleGroup = entry.target.classList.contains('fifth-bubbles-group');

            if (!entry.isIntersecting) return;

            if (isFirstBubbleGroup && mostOfAllMainHeadings) {
                mostOfAllMainHeadings[0]?.classList.add('visible');
                mostOfAllFirstHeading?.classList.remove('hide');
                mostOfAllMainHeadings[0]?.classList.remove('hide');
            } else {
                hideHeading(0);
            }

            if (isSecondBubbleGroup && mostOfAllMainHeadings) {
                mostOfAllFirstHeading?.classList.add('hide');
                headingsAnimation(1);
            } else {
                hideHeading(1);
            }

            if (isForthBubbleGroup && mostOfAllMainHeadings) {
                headingsAnimation(2);
            } else {
                hideHeading(2);
            }

            if (isFifthBubbleGroup && mostOfAllMainHeadings) {
                headingsAnimation(3);
            } else {
                hideHeading(3);
            }
        });
    }, bubbleGroupOptions);

    if (bubbleGroups.length > 0) {
        bubbleGroups.forEach(bubbleGroup => {
            bubbleGroupObserver.observe(bubbleGroup);
        });
    }
};

export default animateSectionFour;
