import React from 'react';
import Icon from '3-components/Icon/Icon';
import ReactModal from 'react-modal';
import Image from '3-components/Image/Image';
import RichText from '3-components/RichText/RichText';
import AddThis from '3-components/AddThis/AddThisReact';
import { ProcessedStoryData } from '4-sections/Modals/Modals';

if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body');
}

interface ModalArticleProps {
    onCloseClick: () => void;
    isOpen: boolean;
    ariaLabel: string;
    processedStoryData: ProcessedStoryData;
    modalParentSelector: HTMLElement;
}

function ModalArticle({ isOpen, onCloseClick, ariaLabel, processedStoryData, modalParentSelector }: ModalArticleProps) {
    const { name, bubbleImage, modalMainBody, contentLink, podcastId, bubbleTitle, title, longFormMainBody, linkToLongformLabel, intro }
    = processedStoryData;
    return (
        processedStoryData && (
            <ReactModal
                isOpen={isOpen}
                onRequestClose={onCloseClick}
                contentLabel={ariaLabel}
                overlayClassName="modal-overlay story left"
                className={`modal-container story ${bubbleImage?.value?.url ? 'story-with-hero-img' : ''}`}
                parentSelector={() => modalParentSelector}
            >
                <button className="modal-close" aria-label="Close modal" type="button" onClick={onCloseClick}><Icon icon="minimize" /></button>
                <div className="modal-scroll">
                    <div className="modal-content">
                        {bubbleImage?.value?.url && (
                            <Image
                                imgSrc={bubbleImage.value.url}
                                imgAlt=""
                                imgCustomClass="modal-header-image"
                                imgWidth="148px"
                                imgHeight="148px"
                            />
                        )}
                        <div className="content-wrapper">
                            <div className="modal-header">
                                <h2>
                                    {(title && title.value)
                                        ? title?.value : name}
                                </h2>
                            </div>
                            <div className="modal-text">
                                {podcastId?.value && (
                                    <>
                                        { intro?.value && (
                                            <RichText
                                                markup={intro?.value}
                                            />
                                        )}
                                        <iframe
                                            title={bubbleTitle?.value ? bubbleTitle?.value : 'Podcast'}
                                            frameBorder="0"
                                            height="150"
                                            width="100%"
                                            scrolling="no"
                                            data-name="pb-iframe-player"
                                            // eslint-disable-next-line max-len
                                            src={encodeURI(`https://www.podbean.com/player-v2/?from=embed&i=${podcastId?.value}&square=1&share=1&download=1&fonts=Arial&skin=1&font-color=auto&rtl=0&logo_link=episode_page&btn-skin=AF005F&size=150`)}
                                        />
                                    </>
                                )}
                                { modalMainBody?.expandedValue?.map(value => (
                                    value.mainBody?.value && (
                                        <RichText
                                            markup={value.mainBody.value}
                                            key={value.contentLink?.id}
                                        />
                                    )
                                ))}
                            </div>
                            {((longFormMainBody && longFormMainBody.value?.length > 0)
                            && (contentLink && contentLink.url))
                            &&
                            <a href={contentLink.url} className="learn-more-cta">{linkToLongformLabel && linkToLongformLabel.value ? linkToLongformLabel.value : 'learn more'} <Icon icon="chevron-right" /></a> }
                        </div>

                    </div>
                </div>
                <footer>
                    <div className="social-share">{isOpen && <AddThis />}</div>
                </footer>
            </ReactModal>
        )
    );
}

export default ModalArticle;
