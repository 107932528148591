/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import CONSTANTS from '../../js/constants';
import useWindowSize from '../../js/utils/WindowDimensions';

interface EraProps {
    scrollLeft?: number;
    dataLoaded?: boolean;
    setScrollTo:
        (x:number| null, y:number|null, absolute?: boolean, height?:number, maxScroll?:number[], eraControl?:boolean)=> void
    modalIsOpen: boolean;
}

interface Eras {
    eraID: string;
    displayName: string;
    displayDate: string;
}

function EraControls(props: EraProps) {
    const { scrollLeft, dataLoaded, setScrollTo, modalIsOpen } = props;
    const scrollArray = useRef([0]);
    const currentEra = useRef(0);
    const [stateEra, setStateEra] = useState(0);
    const windowSize = useWindowSize();
    const widthRef = useRef(0);
    const { ERAS, MAX_MOBILE_WIDTH } = CONSTANTS;
    const displayEras = useRef<Eras[]>();

    const matchEra = () => {
        if (!scrollLeft || scrollLeft < 0 || widthRef.current < MAX_MOBILE_WIDTH) { return; }
        const lastArrayItem = scrollArray.current.length - 1;
        let match = 0;
        const threshold = scrollLeft + (widthRef.current * 0.80);
        if (scrollArray.current[lastArrayItem] <= threshold) {
            match = scrollArray.current.length;
        } else {
            match = scrollArray.current.findIndex(element => element >= threshold);// first in the Array that matches
        }

        currentEra.current = match - 1;
        setStateEra(match - 1);
    };

    useEffect(() => {
        matchEra();
    }, [scrollLeft]);

    useEffect(() => {
        widthRef.current = windowSize.width;
    }, [windowSize]);

    useEffect(() => {
        scrollArray.current = ([]);
        const scrollArrayHolder: number[] = [];
        const emptyEraHolder: string[] = [];
        ERAS.map(({ eraID }) => {
            const eraLink:HTMLElement | null = document.querySelector(`.era-${eraID}`);
            const left = eraLink ? eraLink.offsetLeft : null;
            return left !== null ? scrollArrayHolder.push(left) : emptyEraHolder.push(eraID);
        });
        displayEras.current = ERAS.filter(({ eraID }) => !emptyEraHolder.includes(eraID));
        scrollArray.current = (scrollArrayHolder);
    }, [dataLoaded]);

    const eraScroll = (event:React.MouseEvent<HTMLButtonElement>) => {
        if (modalIsOpen) { return; }
        const target = event.target as HTMLButtonElement;
        if (!target || !target.id) { return; }
        setStateEra(parseInt(target.id, 10));
        const scrollX = (scrollArray.current[target.id] > 0 ? scrollArray.current[target.id] : -1) * -1;
        setScrollTo(scrollX, 0, false, undefined, undefined, true);
    };

    return (
        <div className={`era-controls${modalIsOpen ? ' open-modal' : ''}`}>
            {displayEras.current && displayEras.current.map(({ eraID, displayName, displayDate }, index) => <button type="button" className={`era${index === stateEra ? ' era-match' : ''}`} id={`${index.toString()}`} onClick={eraScroll} key={eraID}><span>{displayName}</span><span className="era-date">{displayDate}</span></button>)}
        </div>
    );
}

export default React.memo(EraControls);
