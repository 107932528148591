/* eslint-disable max-len */
export interface AppData {
    baseUrl: string;
}

declare global {
    interface Window {
        appData: Record<string, string>;
    }
}

const themeIds = {
    'moments-in-time': '51eabb3d-b8b1-48fa-8ad5-c718c3c0c9a2',
    'in-good-company': 'c0b8d8a2-05d3-43f1-bf05-1f72b4f023d8',
    'lasting-legacy': '4531074a-bada-4c90-ab54-66414dd50a4c',
    'beyond-200': 'fa0bacc5-5d7b-48c4-a0b0-f24b335b2e62'
};
const appData = window.appData || { baseUrl: 'https://200.allens.com.au' };
const dataList = 'name,bubbleImage,mainImage,bubbleSize,bubbleTitle,bubbleSubHeader,url,routeSegment,title,contentType,contentLink';
const CONSTANTS = {
    APP_DATA: appData,
    MODAL_SIZE: 704,
    SMALL: 0,
    MEDIUM: 1,
    LARGE: 2,
    ANIMATION_TIME: 500,
    MAX_MOBILE_WIDTH: 767,
    THEMES: [
        {
            themeId: '1',
            displayName: 'About Allens 200',
            icon: 'home',
            link: '',
            dataURL: `${appData.baseUrl}/api/episerver/v2.0/content/${themeIds['moments-in-time']}/children?select=${dataList}`,
            sampleDataUrl: '/assets/sampleDataLocalOnly/moments-in-time.json',
            introContent: 'We have been privileged to witness and contribute to extraordinary social change over our history, helping to shape a more just, equitable and sustainable community. Explore our legacy.',
            themeShareImage: 'Static/twoHundredYears/assets/images/allens-200-share-card.jpg'
        }, {
            themeId: themeIds['moments-in-time'],
            displayName: 'Moments in time',
            link: 'moments-in-time',
            dataURL: `${appData.baseUrl}/api/episerver/v2.0/content/${themeIds['moments-in-time']}/children?select=${dataList},era&expand=era`,
            sampleDataUrl: '/assets/sampleDataLocalOnly/moments-in-time.json',
            introContent: "We are Australia's oldest law firm. We've been there for the nation-building projects, major transactions and reforms that have changed the face of Australia and the region, sometimes in ways you might not expect. Journey through the moments we've been a part of.",
            themeShareImage: 'Static/twoHundredYears/assets/images/moments-in-time-share-card.jpg'
        }, {
            themeId: themeIds['in-good-company'],
            displayName: 'In good company',
            link: 'in-good-company',
            dataURL: `${appData.baseUrl}/api/episerver/v2.0/content/${themeIds['in-good-company']}/children?select=${dataList}`,
            sampleDataUrl: '/assets/sampleDataLocalOnly/in-good-company.json',
            introContent: "We've worked alongside industry pioneers, innovators, and champions of justice and reform, who have shaped the course of our economy and society. Meet the people, companies and icons who have been a part of our story.",
            themeShareImage: 'Static/twoHundredYears/assets/images/in-good-company-share-card.jpg'
        }, {
            themeId: themeIds['lasting-legacy'],
            displayName: 'Lasting legacy',
            link: 'lasting-legacy',
            dataURL: `${appData.baseUrl}/api/episerver/v2.0/content/${themeIds['lasting-legacy']}/children?select=${dataList}`,
            sampleDataUrl: '/assets/sampleDataLocalOnly/lasting-legacy.json',
            introContent: 'We have been privileged to witness and contribute to extraordinary social change over our history, helping to shape a more just, equitable and sustainable community. Explore our legacy.',
            themeShareImage: 'Static/twoHundredYears/assets/images/lasting-legacy-share-card.jpg'
        }, {
            themeId: themeIds['beyond-200'],
            displayName: 'Beyond 200',
            link: 'beyond-200',
            dataURL: `${appData.baseUrl}/api/episerver/v2.0/content/${themeIds['beyond-200']}/children?select=${dataList}`,
            sampleDataUrl: '/assets/sampleDataLocalOnly/beyond-200.json',
            introContent: "A lot has changed in 200 years, including us. One thing that's endured is our commitment to clients, people and communities. Hear from the new generation of Allens about their hopes for the future of business, society and the legal profession.",
            themeShareImage: 'Static/twoHundredYears/assets/images/beyond-200-share-card.jpg'
        }
    ],
    ERAS: [
        { eraID: '1', displayName: 'Convicts & conveyancing', displayDate: '1822–1845' },
        { eraID: '2', displayName: 'Expansion & exploration', displayDate: '1846–1893' },
        { eraID: '3', displayName: 'The business of business', displayDate: '1894–1906' },
        { eraID: '4', displayName: 'A different kind of service', displayDate: '1907–1945' },
        { eraID: '5', displayName: 'The nation recovers', displayDate: '1946–1979' },
        { eraID: '6', displayName: 'Highs & lows', displayDate: '1980–1999' },
        { eraID: '7', displayName: 'A new millennium', displayDate: '2000–2022' }
    ],
    EXTERNAL_MENU: [
        { themeId: 'reflections', displayName: 'Reflections', link: 'https://200.allens.com.au/reflections/' },
        { themeId: 'Allens', displayName: 'Allens', link: 'http://www.allens.com.au', icon: 'external-link' }
    ],
    TIMELINE: 'moments-in-time',
    INTRO: 'allens-200',
    STORY_DATA: `${appData.baseUrl}/api/episerver/v2.0/content/`,
    APP_ELEMENT: 'app-experience-space',
    META_DESC: 'Allens 200 description',
    SHARE_IMG: 'Static/twoHundredYears/assets/images/allens-200-share-card.jpg',
    WEBSITE_URL: 'https://200.allens.com.au'
};

export default CONSTANTS;
