/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useLayoutEffect, useState, useCallback } from 'react';
import useMetaTags from 'react-metatags-hook';
import { useSearchParams, useLocation, useNavigate, Link } from 'react-router-dom';
import Bubble from '3-components/Bubble/Bubble';
import LoadingIndicator from '3-components/LoadingIndicator/LoadingIndicator';
import Modals from '4-sections/Modals/Modals';
import CONSTANTS from '../../js/constants';
import useWindowSize from '../../js/utils/WindowDimensions';

interface Era {
    expandedValue: Record<string, Record<string, number> | null>;
}

interface Data {
    contentType: [string];
    contentLink: Record<string, number>;
    name: string;
    bubbleTitle: Record<string, string | null>;
    bubbleImage: Record<string, Record<string, string> | null>;
    mainImage: Record<string, Record<string, string> | null>;
    bubbleSubHeader: Record<string, string | null>;
    bubbleSize: Record<string, number>;
    era?: Era;
    routeSegment:string;
}

interface BubblesProps {
    setScrollTo:
        (x:number| null, y:number|null, absolute?: boolean, height?:number, maxScroll?:number[], eraControl?:boolean)=> void
    scrollContainer: React.RefObject<HTMLElement>;
    setEraDataLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    inView: boolean
}

function Bubbles(props: BubblesProps) {
    const { setScrollTo, scrollContainer, setEraDataLoaded, inView } = props;
    const { MODAL_SIZE, LARGE, TIMELINE, INTRO, MAX_MOBILE_WIDTH, THEMES, APP_ELEMENT, SHARE_IMG, WEBSITE_URL } = CONSTANTS;
    const [modalBackgroundStyle, setModalBackgroundStyle] = useState({});
    const [modalBackgroundOverlayStyle, setModalBackgroundOverlayStyle] = useState({});
    const [componentReady, setComponentReady] = useState(false);
    const [openArticle, setOpenArticle] = useState<number>();
    const [articleModalIsOpen, setModalIsOpen] = useState(false);
    const [basicModalIsOpen, setBasicModalIsOpen] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [data, setData] = useState([{} as Data]);
    const outro = useRef<Data>();
    const classNames = useRef<string>();
    const visitedData = useRef< number[]>([]);
    const bubContainer = useRef() as React.MutableRefObject<HTMLDivElement>;
    const bubWrapContainer = useRef() as React.MutableRefObject<HTMLDivElement>;
    const articleRef = useRef<number>();
    const timeline = useRef(false);
    const wheelTimeout = useRef() as React.MutableRefObject<NodeJS.Timeout>;
    const [searchParams] = useSearchParams({});
    const live = searchParams.get('story');
    const location = useLocation().pathname.substring(1) ? useLocation().pathname.substring(1).split('/')[0] : INTRO;
    const [locationID, setLocationID] = useState<string | null>(null);
    const scrollingElWidth = bubWrapContainer.current?.offsetWidth;
    const parentElWidth = scrollContainer.current?.offsetWidth || 0;
    const scrollingElHeight = bubWrapContainer.current?.offsetHeight;
    const parentElHeight = scrollContainer.current?.offsetHeight || 0;
    const maxScroll = [scrollingElWidth - parentElWidth, scrollingElHeight - parentElHeight];
    const navigate = useNavigate();
    const introEl = document.querySelector<HTMLElement>('.intro');
    const appSelector = document.querySelector(`#${APP_ELEMENT}`) as HTMLElement;
    const windowSize = useWindowSize();
    const widthRef = useRef(0);
    const locationRef = useRef(location);
    const controllerRef = useRef<AbortController | null>();
    const shareImg = useRef(SHARE_IMG);

    let multiplier = 1;
    let isTouch = false;
    let prevEra: number | null | undefined = null;

    const setLoaded = () => {
        classNames.current = 'loaded interacted';
        setScrollTo(0, 0, true);
        setComponentReady(true);
    };

    const multiply = (multiplierNow: number) => (multiplierNow > 25 ? multiplierNow : multiplierNow * 1.1);
    const loadData = (bubbleData?: string) => {
        classNames.current = 'loading';
        setData([]);
        setEraDataLoaded(false);
        setLocationID(location);
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;
        if (!bubbleData) { return; }
        closeModal();
        fetch(bubbleData, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': 'en' },
            signal: controllerRef.current?.signal
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw Error(`${response.status} - ${response.statusText}`);
            }).then(response => {
                outro.current = undefined;
                controllerRef.current = null;
                setData(response);
                setLoaded();
                outro.current = response.pop();
                if (location !== INTRO) {
                    setBasicModalIsOpen(true);
                }
                if (location === TIMELINE || location === INTRO) {
                    setEraDataLoaded(true);
                }
            }).catch(error => {
            // eslint-disable-next-line no-console
                console.log(error);
            });
    };

    const zoomIn = (bigger: boolean, trackpad = false) => {
        isTouch = trackpad;
        const currentScrollX = scrollContainer.current?.scrollLeft;
        multiplier = multiply(multiplier);
        if (multiplier > 25) {
            isTouch = true;
        }
        const scrollAmount = isTouch ? 20 : 50 * multiplier;
        const containerWidth = bubWrapContainer.current?.offsetWidth ? bubWrapContainer.current?.offsetWidth : 0;
        const leftScroll = maxScroll[0] <= 0 ? containerWidth - parentElWidth : maxScroll[0];

        if (bigger) {
            setScrollTo((currentScrollX ? currentScrollX : 0) - (scrollAmount), 0, true);
            return;
        }
        if (currentScrollX && (currentScrollX <= 0 || currentScrollX > leftScroll)) { return; }
        setScrollTo((currentScrollX ? currentScrollX : 0) + (scrollAmount), 0, true);
    };

    const onWheel = (event: WheelEvent): void => {
        if (event.deltaY > 0 || event.deltaX > 0) {
            zoomIn(false, event.deltaX !== 0);
        } else {
            zoomIn(true, event.deltaX !== 0);
        }
        // while wheel is moving, do not release the lock
        global.clearTimeout(wheelTimeout.current);
        wheelTimeout.current = global.setTimeout(() => { multiplier = 1; isTouch = false; return null; }, 300);
    };

    const cancelWheel = (event: WheelEvent) => wheelTimeout.current && event.preventDefault();

    function WheelListener(event: WheelEvent) {
        if (articleRef.current || widthRef.current < MAX_MOBILE_WIDTH) { return; }
        event.preventDefault();
        cancelWheel(event);
        onWheel(event);
    }

    const KeyboardListener = (event: KeyboardEvent) => {
        if (event.code === 'ArrowRight') {
            zoomIn(false);
        } else if (event.code === 'ArrowLeft') {
            zoomIn(true);
        }
    };
    const closeModal = () => {
        if (!openArticle && !articleRef.current) { return null; }
        setScrollTo(null, null, true, undefined, maxScroll);
        if (widthRef.current > MAX_MOBILE_WIDTH) {
            setModalBackgroundStyle({ ...modalBackgroundStyle, opacity: 0, transition: 'transform 0.2s, opacity 0.2s' });
            setModalBackgroundOverlayStyle({ ...modalBackgroundOverlayStyle, opacity: 0 });
        }
        setOpenArticle(undefined);
        setModalIsOpen(false);
        articleRef.current = undefined;
        // setSearchParams({ });
        navigate({ pathname: `/${timeline.current ? TIMELINE : locationRef.current}`, search: '' });
        return null;
    };

    const openModal = (
        target:HTMLButtonElement | EventTarget,
        id:number,
        img?: string,
        routeSegment?:string,
        lightbox?:boolean,
        link?: boolean
    ) => {
        if (id === articleRef.current) { return; }
        setModalIsOpen(false);
        articleRef.current = id;
        if (!link && routeSegment) {
            const newStory = routeSegment;
            navigate({ pathname: `/${timeline.current ? TIMELINE : locationRef.current}`, search: `?story=${newStory}` });
        }
        setOpenArticle(id);

        const el = target as HTMLElement;
        const bubSize = 0;
        const x = el?.parentElement?.offsetLeft;
        const y = 0;
        const scrollX = x ? x : 0;
        let xScroll = 0;
        const modalOffset = MODAL_SIZE + 32;
        xScroll = (modalOffset - scrollX);
        visitedData.current = [...visitedData.current, id];

        if (lightbox) {
            setBasicModalIsOpen(true);
            if (widthRef.current > MAX_MOBILE_WIDTH) {
                setModalBackgroundStyle({ ...modalBackgroundStyle, opacity: 0, transition: 'transform 0.2s, opacity 0.2s' });
                setModalBackgroundOverlayStyle({ ...modalBackgroundOverlayStyle, opacity: 0 });

                setScrollTo(xScroll, y, false, bubSize);
            }
            return;
        }

        if (widthRef.current <= MAX_MOBILE_WIDTH && el && el.parentElement) {
            setScrollTo(0, -1 * el.parentElement.offsetTop, false, bubSize);
        } else {
            setScrollTo(xScroll, y, false, bubSize);
            const translate = `translateX(${xScroll * -1}px)`;
            const beginModalBackground = {
                opacity: 0,
                transform: translate,
                backgroundImage: img ? `url(${img})` : '',
                transition: 'opacity 0s'
            };
            const endModalBackground = {
                transform: `${translate}`,
                backgroundImage: img ? `url(${img})` : '',
                opacity: 1
            };

            if (articleRef.current) {
                setModalBackgroundStyle({ ...modalBackgroundStyle, opacity: 0, transition: 'transform 0.2s, opacity 0.5s' });
                setTimeout(() => { setModalBackgroundStyle({ ...beginModalBackground }); }, 500);
                setTimeout(() => { setModalBackgroundStyle(endModalBackground); }, 510);
            } else {
                setModalBackgroundStyle({ ...beginModalBackground });
                setTimeout(() => { setModalBackgroundStyle(endModalBackground); }, 10);
            }
            setModalBackgroundOverlayStyle({ opacity: 0.7 });
        }
        setModalIsOpen(true);
    };

    const handleBasicModalClose = () => {
        setBasicModalIsOpen(false);
        closeModal();
    };

    useEffect(() => {
        classNames.current = 'loading';
    }, []);

    useEffect(() => {
        widthRef.current = windowSize.width;
    }, [windowSize]);

    useMetaTags({
        title: `Allens 200 ${displayName}`,
        description: `200 years of Allens - ${displayName}`,
        charset: 'utf-8',
        lang: 'en',
        metas: [
            { name: 'url', content: window.location.href }
        ],
        openGraph: {
            title: `Allens 200 ${displayName}`,
            image: `${WEBSITE_URL}/${shareImg.current}`,
            site_name: WEBSITE_URL
        },
        twitter: {
            title: `Allens 200 ${displayName}`,
            image_src: `${WEBSITE_URL}/${shareImg.current}`
        }
    }, [displayName]);

    useLayoutEffect(() => {
        if (inView) {
            document.addEventListener('keydown', KeyboardListener);
            document.addEventListener('wheel', WheelListener, { passive: false });
            if (introEl) { introEl.style.display = 'none'; appSelector.style.position = ''; }
            if (location === INTRO) { navigate(TIMELINE); }
        } else {
            setBasicModalIsOpen(false);
        }
        return () => {
            document.removeEventListener('wheel', WheelListener);
            document.removeEventListener('keydown', KeyboardListener);
        };
    }, [inView]);

    useEffect(() => {
        locationRef.current = location;
        const isIntro = location === INTRO;
        const locationLink =
        !isIntro ? THEMES.filter(({ link }) => link === location) : THEMES.filter(({ link }) => link === TIMELINE);
        shareImg.current = locationLink.length > 0 ? locationLink[0]?.themeShareImage : SHARE_IMG;
        const dataURL = locationLink.length > 0 ? locationLink[0]?.dataURL : '';
        setDisplayName((locationLink.length > 0 && !isIntro) ? locationLink[0]?.displayName : 'Introduction');
        const timelineCheck = (location === TIMELINE || isIntro);
        if (isIntro) {
            if (introEl) {
                introEl.style.display = 'block';
                introEl.classList.add('bounced');
                appSelector.style.position = 'relative';
            }
        } else if (introEl) { introEl.style.display = 'none'; appSelector.style.position = ''; introEl.classList.remove('bounced'); }

        if (timelineCheck && timeline.current === timelineCheck) { return; }
        timeline.current = timelineCheck;
        loadData(encodeURI(dataURL));
        setEraDataLoaded(false);
        const appClassList = Array.from(appSelector.classList);
        appSelector.classList.remove(...appClassList);
        appSelector.classList.add(APP_ELEMENT, location);
    }, [location]);

    const memoizedOpenModal = useCallback(openModal, []);
    const memoizedCloseModal = useCallback(closeModal, []);
    const memoizedCloseBasicModal = useCallback(handleBasicModalClose, []);

    return (
        (componentReady) ? (
            <>
                <div className={`bubbles${timeline.current ? ' timeline' : ''}`} ref={bubContainer}>
                    <div className={`bubble-wrap ${classNames ? classNames.current : ''}${openArticle ? ' open-modal' : ''}`} ref={bubWrapContainer}>
                        {data.length > 1 && data.map(b => {
                            const { contentLink, name, bubbleImage, mainImage, bubbleSubHeader, routeSegment, bubbleTitle } = b;
                            const { id } = contentLink;
                            const imageUrl = bubbleImage?.value?.url;
                            const mainImageUrl = mainImage?.value?.url;
                            const bubbleSize = b.bubbleSize.value;
                            const bubTitleText = bubbleTitle?.value;
                            const { contentType } = b;
                            let bubbleContentType = '';
                            if (contentType && contentType.length > 0) {
                                bubbleContentType = contentType[contentType.length - 1];
                            }
                            let eraFirst = false;
                            const era = b.era?.expandedValue?.eraId?.value;
                            eraFirst = !!(era && (prevEra !== era));
                            prevEra = era;
                            const subtitle = bubbleSubHeader.value;
                            const visited = visitedData.current.some(visits => visits === id);

                            return (
                                <Bubble
                                    key={id}
                                    title={bubTitleText ? bubTitleText : name}
                                    imageUrl={imageUrl}
                                    mainImageUrl={mainImageUrl ? mainImageUrl : imageUrl}
                                    bubbleSize={eraFirst ? LARGE : bubbleSize}
                                    subtitle={subtitle ? subtitle : ''}
                                    open={openArticle === id}
                                    openModal={memoizedOpenModal}
                                    id={id}
                                    live={live === routeSegment}
                                    eraFirst={eraFirst}
                                    era={era}
                                    visited={visited}
                                    closeModal={memoizedCloseModal}
                                    routeSegment={routeSegment}
                                    interacted={componentReady}
                                    bubbleContentType={bubbleContentType}
                                />
                            );
                        })}
                        {(outro.current && data.length > 1) && (
                            <div className="outro">
                                <div className="container">
                                    <p className="text-7xl-roman title">{outro.current.bubbleTitle.value}</p>
                                    <p>{outro.current.bubbleSubHeader.value}</p>
                                    <div className="text-3xl-roman nav-intro">Dive into Allens&apos; 200 year history</div>
                                    <nav>
                                        {/* TODO make these not show current */}

                                        {location !== 'moments-in-time' && <Link to="/moments-in-time">Moments in Time</Link>}
                                        {location !== 'in-good-company' && <Link to="/in-good-company">In Good Company</Link>}
                                        {location !== 'lasting-legacy' && <Link to="/lasting-legacy">Lasting Legacy</Link>}
                                        {location !== 'beyond-200' && <Link to="/beyond-200">Beyond 200</Link>}
                                    </nav>
                                </div>
                            </div>
                        )}
                    </div>
                    <LoadingIndicator className={classNames.current} />
                    <div style={modalBackgroundStyle} aria-hidden="true" onClick={closeModal} className={`no-drag${openArticle ? ' open-modal' : ''}`}>
                        <div style={modalBackgroundOverlayStyle} className="no-drag-overlay" />
                    </div>
                </div>
                <Modals
                    articleModalIsOpen={articleModalIsOpen}
                    openArticle={openArticle}
                    basicModalIsOpen={basicModalIsOpen}
                    handleBasicModalClose={memoizedCloseBasicModal}
                    introTheme={openArticle ? null : locationID}
                    displayName={displayName}
                />
            </>
        ) : (
            <LoadingIndicator className={classNames.current} />
        )
    );
}

export default React.memo(Bubbles);
