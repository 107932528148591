import React from 'react';

interface RichTextProps {
   markup: string;
   notRich?: boolean;
}

function RichText(props: RichTextProps) {
    const { markup, notRich } = props;

    function createMarkup() {
        return { __html: markup };
    }

    if (markup) {
        return <div className={notRich ? '' : 'rich-text'} dangerouslySetInnerHTML={createMarkup()} />;
    }
    return null;
}

export default RichText;
