// @ts-nocheck
export default function refreshAddThis() {
    if (document.getElementById('script-tag')) {
        const addThisTimer = setInterval(() => {
            if (typeof addthis !== 'undefined' && typeof addthis.layers.refresh !== 'undefined') {
                clearInterval(addThisTimer);
                addthis.layers.refresh();
            }
        }, 100);
    }
}
