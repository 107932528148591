const animateSectionTwo = () => {
    const introSectionTwo = document.querySelector('.section-two');

    const Options = { threshold: 0.2 };
    const sectionTwoObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                introSectionTwo?.classList.remove('show');
                return;
            }
            introSectionTwo?.classList.add('show');
        });
    }, Options);

    if (introSectionTwo) {
        sectionTwoObserver.observe(introSectionTwo);
    }
};

export default animateSectionTwo;
