// This function will attach the keypress of "enter" to trigger the element's click event
// This function is only needed on 'non-native' clickable elements like div / span ....
// Things like <a> <input> <button> will not this function
// Option to include space bar as click

export default function attachEnterAsClick(element, space = false) {
    if (!element) { return; }
    element.addEventListener('keypress', e => {
        const spaceClick = !!((space && e.code === 'Space'));
        if (e.key === 'Enter' || spaceClick) {
            e.preventDefault();
            // Trigger click event on the same element
            e.target.click();
        }
    });
}
