// @ts-nocheck
import React, { useEffect } from 'react';
import refreshAddThis from './AddThisHelpers';

function AddThis() {
    useEffect(() => {
        refreshAddThis();
    }, []);

    return (
        <div className="addthis_inline_share_toolbox_qikq" />
    );
}

export default AddThis;
