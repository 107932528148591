const animateSectionOne = () => {
    const subSections = document.querySelectorAll('.section-one .sub-section');
    const introSectionOne = document.querySelector('.section-one');
    const scrollToTopBtn = document.querySelector('.jump-in');
    const appMenu = document.querySelector('.app-experience-space .menu') as HTMLElement;

    let prevClass = '' as string | undefined;

    const Options = { threshold: 0.05 };
    const sectionOneObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            }
            scrollToTopBtn?.classList.add('show');
            if (appMenu) {
                appMenu.dataset.show = 'show';
            }
            const target = entry.target as HTMLElement;
            if (target.dataset.animate) { introSectionOne?.classList.add(target.dataset.animate); }
            if ((prevClass && target.dataset.animate) && (prevClass !== target.dataset.animate)) { introSectionOne?.classList.remove(prevClass); }
            prevClass = target.dataset.animate;
        });
    }, Options);

    if (subSections.length > 0) {
        subSections.forEach(sub => {
            sectionOneObserver.observe(sub);
        });
    }
    let topOffset = 0;
    const scrollListener = () => {
        if (introSectionOne?.classList.contains('animate-three') || introSectionOne?.classList.contains('animate-four')) {
            if (topOffset === 0) {
                topOffset = introSectionOne.getBoundingClientRect().top;
            }
        }
    }

    document.addEventListener('scroll', scrollListener);
    return () => {
        document.removeEventListener('scroll', scrollListener);
    };
};

export default animateSectionOne;
