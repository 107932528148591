/**
 * components.js
 *
 * Import the JS for your components here.
 *
 */
import './Accordion/Accordion';
import './Buttons/stopDisabledLinksFiring';
import './Modal/Modal';
import './Slider/Swiper';
import './DownloadForm/DownloadForm';
import ComponentInit from '../js/utils/ComponentInit';
import VideoEmbed from './VideoEmbed/VideoEmbed';

// Format:
// data-component-name: ComponentFunction
// we shall order these in page load critical first
const componentList = { VideoEmbed };

export default ComponentInit(componentList);
