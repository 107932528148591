import React, { useEffect, useState } from 'react';
import useMetaTags from 'react-metatags-hook';
import ModalArticle from '3-components/Modal/ModalArticle';
import ModalBasic from '3-components/Modal/ModalBasic';
import Slider, { Slide } from '3-components/Slider/Slider';
import ThemeIntro from '3-components/ThemeIntro/ThemeIntro';
import CONSTANTS from '../../js/constants';

interface ModalsProps {
    articleModalIsOpen: boolean;
    openArticle: number | undefined;
    basicModalIsOpen:boolean;
    handleBasicModalClose: () => void;
    introTheme?: string | null;
    displayName: string;
}

interface mainBody {
    videoUrl?: Record<string, string | null> | null;
    contentLink?: Record<string, number>;
    mainBody?: Record<string, string | null> | null;
    image?: Record<string, Record<string, string | undefined>> | null;
    videoImage?: Record<string, Record<string, string | undefined>> | null;
    subTitle?: Record<string, string> | null;
}

interface expandedValue {
    expandedValue: [mainBody]
}

export interface ProcessedStoryData {
    bubbleImage?: Record<string, Record<string, string> | null>;
    bubbleTitle?: Record<string, string>;
    contentLink?: Record<string, string>;
    contentType: [string];
    longFormMainBody?: Record<string, []>;
    linkToLongformLabel?: Record<string, []>;
    mainImage?: Record<string, string>;
    metaTitle?: string;
    modalMainBody?: expandedValue;
    name: string | null;
    podcastId?: Record<string, string>;
    title?: Record<string, string>;
    intro?: Record<string, string>;
}

function Modals(props: ModalsProps) {
    const {
        articleModalIsOpen,
        openArticle,
        basicModalIsOpen,
        handleBasicModalClose,
        introTheme,
        displayName
    } = props;

    const { STORY_DATA, WEBSITE_URL } = CONSTANTS;
    const modalParentSelector = document.querySelector(`#${CONSTANTS.APP_ELEMENT}`) as HTMLElement;
    const [processedStoryData, setProcessedStoryData] = useState<ProcessedStoryData | null>(null);
    const [slidesData, setSlidesData] = useState<[Slide?] | undefined>();
    // eslint-disable-next-line max-len
    const storyURL = `${STORY_DATA}${openArticle}?expand=bubbleImage,bubbleTitle,contentLink,contentType,mainImge,metaTitle,modalMainBody,name,podcastId,title,longFormMainBody,linkToLongformLabel`;

    const handleClose = () => {
        setProcessedStoryData(null);
        setSlidesData(undefined);
        handleBasicModalClose();
    };

    const useStoryData = (data: ProcessedStoryData) => {
        const {
            name,
            bubbleImage,
            modalMainBody,
            contentLink,
            metaTitle,
            contentType,
            podcastId,
            bubbleTitle,
            title,
            longFormMainBody,
            linkToLongformLabel,
            intro
        }
        = data;
        const processedData =

        {
            name,
            bubbleImage,
            modalMainBody,
            contentLink,
            metaTitle,
            contentType,
            podcastId,
            bubbleTitle,
            title,
            longFormMainBody,
            linkToLongformLabel,
            intro
        };
        setProcessedStoryData(processedData);

        if (!articleModalIsOpen) {
            const slides:[Slide?] = [];
            modalMainBody?.expandedValue?.map(value => {
                let slide;
                if (value.videoUrl?.value) {
                    slide = {
                        id: value.contentLink?.id,
                        videoSrc: value.videoUrl?.value,
                        videoThumb: value.videoImage?.value.url,
                        caption: value.subTitle?.value
                    };
                } else if (value.image?.value?.url) {
                    slide = {
                        id: value.contentLink?.id,
                        imgSrc: value.image.value.url,
                        caption: value.subTitle?.value
                    };
                }
                if (!slide) { return null; }
                return slides.push(slide);
            });
            if (slides) {
                setSlidesData(slides);
            }
        }
    };

    // openArticle
    const loadData = (dataURL?: string) => {
        if (!dataURL) { return; }
        fetch(storyURL, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': 'en' }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw Error(`${response.status} - ${response.statusText}`);
            }).then(response => {
                useStoryData(response);
            }).catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    };

    useEffect(() => {
        if (!openArticle) { return; }
        loadData(encodeURI(storyURL));
    }, [openArticle]);

    useMetaTags({
        title: `Allens 200${processedStoryData ? `: ${processedStoryData.name}` : `: ${displayName}`}`,
        description: `200 years of Allens - ${processedStoryData ? ` ${processedStoryData.name}` : ` ${displayName}`}`,
        charset: 'utf-8',
        lang: 'en',
        metas: [
            { name: 'url', content: window.location.href }
        ],
        openGraph: {
            title: `Allens 200${processedStoryData ? `: ${processedStoryData.name}` : `: ${displayName}`}`,
            site_name: WEBSITE_URL
        }
    }, [processedStoryData, displayName]);

    return (
        <>
            { articleModalIsOpen && openArticle && processedStoryData && (
                <ModalArticle
                    isOpen={articleModalIsOpen}
                    onCloseClick={handleClose}
                    ariaLabel="Story"
                    processedStoryData={processedStoryData}
                    modalParentSelector={modalParentSelector}
                />
            )}
            {basicModalIsOpen && (
                <ModalBasic
                    isOpen={basicModalIsOpen}
                    onCloseClick={handleClose}
                    ariaLabel="Modal Images"
                    modalTitle=""
                    content=""
                    modalParentSelector={modalParentSelector}
                    hasSlides={slidesData && slidesData.length > 0}
                >
                    {slidesData && (
                        <Slider
                            slides={slidesData}
                            thumbs={true}
                        />
                    )}
                    {introTheme && (
                        <ThemeIntro introTheme={introTheme} handleClose={handleClose} />
                    )}
                </ModalBasic>
            )}
        </>
    );
}

export default Modals;
