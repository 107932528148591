/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import CONSTANTS from '../../js/constants';

interface BubbleProps {
    title: string;
    subtitle?:string;
    imageUrl?: string;
    bubbleSize: number;
    open: boolean;
    id: number;
    live: boolean;
    eraFirst: boolean;
    era?: number;
    visited: boolean;
    openModal: (
        target:HTMLButtonElement | EventTarget,
        id:number,
        img?:string,
        title?:string,
        lightbox?:boolean,
        link?:boolean
    ) => void;
    closeModal: () => void;
    routeSegment: string;
    interacted: boolean;
    mainImageUrl?: string;
    bubbleContentType?: string;
}

function Bubble(props: BubbleProps) {
    const {
        title,
        imageUrl,
        bubbleSize,
        open,
        openModal,
        id,
        subtitle,
        live,
        eraFirst,
        era,
        visited,
        closeModal,
        routeSegment,
        interacted,
        mainImageUrl,
        bubbleContentType
    } = props;

    const bubButton = useRef() as React.MutableRefObject<HTMLButtonElement>;
    const { SMALL, LARGE, ANIMATION_TIME } = CONSTANTS;
    const positions = ['start', 'center', 'end'];
    const randomY = positions[Math.floor(Math.random() * positions.length)];
    const randomX = positions[Math.floor(Math.random() * positions.length)];
    const getRandomInt = () => Math.floor(((Math.random() * 96) - 48) / 2);
    const lightbox = bubbleContentType === 'MediaBubble';
    const teardrop = open && !lightbox;
    const displayEra = era?.toString();
    const bubTransform = `translateY(${getRandomInt()}px)`;
    const [wrapperStyle] = useState({ placeSelf: `${randomY} ${randomX}`, transform: bubTransform });
    const style = { backgroundImage: imageUrl && `url(${imageUrl})` };

    useEffect(() => {
        // if it is a direct link to content in modal
        if (open || !live) { return; }
        setTimeout(() => {
            openModal(
                bubButton.current,
                id,
                mainImageUrl && `${mainImageUrl}`,
                routeSegment,
                lightbox,
                true
            );
        }, interacted ? 0 : ANIMATION_TIME * 2);
    }, [open]);

    const handleClick = (e:React.MouseEvent<HTMLButtonElement>) => {
        if (open) {
            closeModal();
            return;
        }
        openModal(e.target, id, mainImageUrl && `${mainImageUrl}`, routeSegment, lightbox, undefined);
    };

    return (
        <div style={eraFirst ? {} : wrapperStyle} className={`bubble size-${bubbleSize}${imageUrl && !eraFirst ? ' image-bg' : ''}${displayEra ? ` era-${displayEra}` : ''}${teardrop ? ' teardrop' : ''}${eraFirst && displayEra ? ' era-first' : ''}`}>
            <button type="button" className={`bubble-inner left size-${bubbleSize}${teardrop ? ' teardrop' : ''}${eraFirst && displayEra ? ' era-first' : ''}${(visited && !eraFirst) ? ' visited' : ''}${imageUrl ? '' : ' stroke'}`} ref={bubButton} onClick={e => handleClick(e)}>
                {eraFirst && displayEra && <div className="strike-through" />}
                <div style={style} className={`${imageUrl ? ' image-bg' : ''} bubble-background`} />
            </button>
            {(bubbleSize !== SMALL && (title || (subtitle && bubbleSize === LARGE))) && (
                <div className="text-content">
                    <p className="bubble-title">{(title && bubbleSize !== SMALL) && title}</p>
                    {(subtitle && bubbleSize === LARGE) && <p className="bubble-subtitle">{subtitle}</p>}
                </div>
            )}
        </div>
    );
}
export default React.memo(Bubble);
