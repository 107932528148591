import animateSectionOne from '4-sections/IntroSectionOne/IntroSectionOne';
import animateSectionTwo from '4-sections/IntroSectionTwo/IntroSectionTwo';
import animateSectionThree from '4-sections/IntroSectionThree/IntroSectionThree';
import animateSectionFour from '4-sections/IntroSectionFour/IntroSectionFour';

const introEl = document.querySelector('.intro');

const animateIntroSections = () => {
    const mainContainer = document.querySelector<HTMLElement>('.intro');
    if (mainContainer) {
        document.addEventListener('scroll', () => {
            mainContainer.style.backgroundPositionY = `-${String(window.scrollY / 10)}px`;

            if (window.scrollY === 0) {
                const sectionOneEl = document.querySelector<HTMLElement>('.section-one');
                if (sectionOneEl) {
                    sectionOneEl.className = '';
                    sectionOneEl.classList.add('section-one');
                }
            }
        });
    }

    animateSectionOne();
    animateSectionTwo();
    animateSectionThree();
    animateSectionFour();
};

// get a reference to our predefined button
const scrollToTopBtn = document.querySelector('.jump-in');

function scrollToBottom() {
    const scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    window.scrollTo({
        top: scrollableHeight,
        behavior: 'smooth'
    });
}

if (introEl) {
    document.addEventListener('DOMContentLoaded', () => {
        if (scrollToTopBtn !== null) {
            scrollToTopBtn.addEventListener('click', scrollToBottom);
        }
        animateIntroSections();
    });
}
