import React, { useEffect } from 'react';
import CONSTANTS from '../../js/constants';

interface ThemeIntroProps {
    introTheme: string;
    handleClose: () => void;
}

function ThemeIntro(props: ThemeIntroProps) {
    const { introTheme, handleClose } = props;
    const { THEMES } = CONSTANTS;
    const { displayName, introContent } = THEMES.filter(({ link }) => link === introTheme)[0];

    useEffect(() => {
        document.addEventListener('wheel', handleClose, { passive: false });
        return () => {
            document.removeEventListener('wheel', handleClose);
        };
    }, []);

    // if (displayName || introContent) {
    return (
        <div className="theme-intro rich-text">
            <div className="intro-logo">
                <img src="/Static/twoHundredYears/assets/illustrations/logo-horizontal-stacked.svg" alt="Allens linklaters, 200 years" />
            </div>
            <div className="intro-content-wrapper">
                <div className="title">{displayName}</div>
                <div className="intro-content">{introContent}</div>
            </div>
        </div>
    );
    // }
}

export default ThemeIntro;
