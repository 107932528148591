import { getFileExtension, getVimeoId, getYoutubeId } from './video-embed-utils';

interface VideoEmbedSettings {
    videoSrc: string;
    aspectRatio?: string;
    hasCoverImg?: boolean;
}

interface VideoEmbedProps {
    el: HTMLElement;
    props: VideoEmbedSettings
}

const PLAY_BTN_CLASS = 'video-embed-play-btn';
const VIDEO_CONTAINER_CLASS = 'video-embed-video-container';
const VIDEO_CLASS = 'video-embed-video';

const getVideoData = (videoSrc: string, aspectRatio?: string, hasCoverImg?: boolean) => { // bloody type script how to we point this to VideoEmbedSettings?
    let videoType;
    let videoEl;

    // FROM YOUTUBE
    if (/^.*(youtube.com\/|youtu.be\/).*$/.test(videoSrc)) {
        videoType = 'youtube';
        const videoId = getYoutubeId(videoSrc);
        videoEl = `
        <iframe
            src="https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&color=white${hasCoverImg ? '&autoplay=1' : ''}"
            class="${VIDEO_CLASS}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
        >
        </iframe>`;

        // FROM VIMEO
    } else if (videoSrc.includes('vimeo.com/')) {
        videoType = 'vimeo';
        const videoId = getVimeoId(videoSrc);
        videoEl = `
        <iframe
            class="${VIDEO_CLASS}"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            src="https://player.vimeo.com/video/${videoId}${hasCoverImg ? '?autoplay=1' : ''}"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
        >
        </iframe>`;

        // FROM vio
    } else if (videoSrc.includes('viostream.com')) {
        videoType = 'viostream';
        videoEl = `
        <iframe
            class="${VIDEO_CLASS}"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            src=${videoSrc}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
        >
        </iframe>`;

        // FROM UPLOAD
    } else {
        videoType = 'upload';
        const fileExt = getFileExtension(videoSrc);
        videoEl = `
        <video class="${VIDEO_CLASS}" controls width="640" height="360" ${hasCoverImg ? 'autoplay' : ''}>
            <source src="${videoSrc}" type="video/${fileExt}">
            Sorry, your browser doesn't support embedded videos.
        </video>
        `;
    }

    return {
        videoType,
        videoEl: `
        <div class="${VIDEO_CONTAINER_CLASS}" style="--aspect-ratio: ${aspectRatio}">
            ${videoEl}
        </div>
        `
    };
};

const VideoEmbed = ({ el, props }: VideoEmbedProps) => {
    const { videoSrc, aspectRatio = '16/9', hasCoverImg = false } = props;

    if (!videoSrc) return;

    const videoData = getVideoData(videoSrc, aspectRatio, hasCoverImg);
    const videoContainer = el.querySelector('.video-embed-container');

    const showVideo = () => {
        const isVideoExist = el.querySelector(`.${VIDEO_CLASS}`);

        if (!isVideoExist && videoContainer) {
            videoContainer.insertAdjacentHTML('afterbegin', videoData.videoEl);
        }

        el.classList.add('show-video');
    };

    if (hasCoverImg) {
        const playBtn = el.querySelector(`.${PLAY_BTN_CLASS}`) as HTMLButtonElement;

        playBtn.addEventListener('click', () => {
            showVideo();
        });
    } else {
        showVideo();
    }
};

export default VideoEmbed;
