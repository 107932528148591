import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AddThis from '3-components/AddThis/AddThisReact';
import Icon from '3-components/Icon/Icon';
import CONSTANTS from '../../js/constants';

interface MenuProps {
    inView: boolean
}

function Menu(props: MenuProps) {
    const { inView } = props;

    const location = useLocation().pathname.substring(1).split('/')[0];
    const locationLink = CONSTANTS.THEMES.filter(({ link }) => link === location);
    const liveLink = locationLink.length > 0 ? locationLink[0]?.themeId : CONSTANTS.THEMES[0].themeId;
    const [openRef, setOpenRef] = useState(false);

    const openClose = (event: EventTarget, close?:boolean) => {
        if (close) { return setOpenRef(false); }
        return setOpenRef(!openRef);
    };

    return (
        <div className="menu-wrapper">
            <div className={`menu ${openRef ? 'open' : 'closed'} ${inView ? ' inview' : ''}`} data-show="no-show">
                {openRef && <button type="button" aria-label="close menu" className="menu-overlay" onClick={e => openClose(e.target, false)} />}
                <div className="menu-scroll">
                    <nav className="experience-menu">
                        {CONSTANTS.THEMES.map(({ themeId, link, displayName, icon }) => (
                            <Link to={link} key={link} onClick={e => openClose(e.target, themeId !== liveLink)} className={themeId === liveLink ? 'active' : ''}>
                                {icon && <Icon icon={icon} />}
                                {displayName}
                            </Link>
                        ))}
                    </nav>
                    <nav className="external-menu">
                        {CONSTANTS.EXTERNAL_MENU.map(({ themeId, link, displayName, icon }) => (
                            <a
                                href={link}
                                key={link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={themeId === liveLink ? 'active' : ''}
                            >{displayName}
                                {icon && <Icon icon={icon} />}
                            </a>
                        ))}
                    </nav>
                    <nav className="social-share">{openRef && <AddThis />}</nav>
                </div>
                <button type="button" aria-label={`${openRef ? 'close' : 'open'} menu`} className="menu-control" onClick={event => openClose(event.target)} />
            </div>
            <footer className="app-footer">
                <div className="copy">
                    <p className="text-xs">This website contains images and stories of those who came before us and includes names of deceased persons.</p>
                </div>
                <div className="links">
                    <a href="https://www.allens.com.au/general/site-information/privacy/">
                        Privacy
                    </a>
                    <a href="https://www.allens.com.au/general/site-information/disclaimer/">
                        Disclaimer
                    </a>
                    <p className="text-xs">Allens is an independent partnership operating in alliance with Linklaters LLP. © 2024 Allens, Australia</p>
                </div>
            </footer>
        </div>
    );
}

export default Menu;
